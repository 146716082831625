/* eslint-disable func-names */
$('html').on('promocodes.ready', () => {
  const hasForm = $('.js-payment-page').length > 0;
  if (!hasForm) { return; }

  let skipScroll = false;

  // summary display elements
  const summaryAmount = $('.js-summary-amount');
  const summaryDonationRow = $('.js-summary-donation-row');
  const summaryDonation = $('.js-summary-donation');
  const summaryTotal = $('.js-summary-total');
  const summaryFrequency = $('.js-summary-frequency');
  const summarySubscriptionType = $('.js-summary-subscription-type');
  const summaryPromoValue = $('.js-summary-promo-value');

  // applying promo code
  const promoCodeInput = $('.js-promo-input');

  // form fields
  const membershipProductId = $('.js-promo-product-id');
  const membershipReceivesMagazine = $('.js-receives-magazine');
  const promoCodeId = $('.js-promo-code-id');
  const promoCodeDiscount = $('.js-promo-code-discount');
  const donationRadios = $('.js-donation-radio');
  const donationAmountInput = $('.js-donation-amount');
  let donationValue = 0;

  // promo code methods
  const {
    updatePromoDetails,
    setInvalidPromoCode
  } = window.promoCodes;

  const formatPrice = (price) => (
    `$${parseFloat(price).toFixed(2).toString().replace(/\.00$/, '')}`
  );

  const hasSelection = () => {
    if ($('.js-membership-product-radio:checked').length > 0) { return true; }
    if ($('.js-membership-options-option-active button').data('digital-pass')) { return true; }
    return false;
  };

  const getProductSelection = () => {
    if ($('.js-membership-product-radio:checked').length > 0) {
      return $('.js-membership-product-radio:checked').data('membership-product-id');
    }
    if ($('.js-membership-options-option-active button').data('digital-pass')) {
      return $('.js-membership-options-option-active button').data('membership-product-id');
    }
    return '';
  };

  const digitalPassSelected = () => {
    if ($('.js-membership-options-option-active button').data('digital-pass')) {
      return $('.js-membership-options-option-active button').data('membership-product-id');
    }
    if ($('.js-membership-product-radio[data-digital-pass]:checked').length > 0) {
      return $('.js-membership-product-radio:checked').data('membership-product-id');
    }
    return false;
  };

  const updateHiddenMembershipFields = () => {
    const selectedOpt = $('.js-membership-options-option-active');
    if (selectedOpt.length <= 0) { return; }
    const productType = selectedOpt.find('.js-membership-option-select').data('product-type');

    if (hasSelection()) {
      membershipProductId.val(getProductSelection());
    }

    if (productType === 'online_and_magazine') {
      membershipReceivesMagazine.val('true');
    } else {
      // Inlcudes both digital_pass flagged and online options..
      membershipReceivesMagazine.val('false');
    }
  };

  const showDigitalTerms = () => {
    if (digitalPassSelected()) {
      $('.js-digital-option').show();
      $('.js-membership-form-digital-terms').show();
    } else {
      $('.js-membership-form-digital-terms').hide();
    }
  };

  const updateSummary = () => {
    let product;
    let digital = false;
    let magazine = membershipReceivesMagazine.val();

    // Try for a digital pass product...
    if (digitalPassSelected()) {
      // Show the terms...
      product = $('.js-membership-options-option-active button');

      digital = 'true';
      magazine = false;
    } else {
      product = $('.js-membership-product-radio:checked');
    }

    if (donationValue) {
      summaryDonationRow.show();
    } else {
      summaryDonationRow.hide();
    }

    if (product.length > 0) {
      const frequency = product.data('membership-product-term');
      summaryFrequency.text(frequency);

      const promoDiscount = parseFloat(promoCodeDiscount.val() || 0.00) / 100;
      const membershipValue = parseFloat(product.data('membership-product-amount') || 0.00);
      const totalValue = membershipValue + donationValue - promoDiscount;

      summaryPromoValue.text(`-${formatPrice(promoDiscount)}`);
      summaryAmount.text(formatPrice(membershipValue));
      summaryDonation.text(formatPrice(donationValue));
      summaryTotal.text(formatPrice(totalValue));
      $('input[type="submit"]').prop('disabled', false);
    } else {
      summaryFrequency.text('-');
      summaryAmount.text('-');
      summaryTotal.text('-');
      $('input[type="submit"]').prop('disabled', true);
    }

    $('.js-membership-online-icon').hide();
    $('.js-membership-digital-icon').hide();
    $('.js-membership-magazine-online-icon').hide();

    if (magazine === 'true') {
      $('.js-membership-magazine-online-icon').show();
      summarySubscriptionType.text('Online + Magazine Membership');
    } else if (digital === 'true') {
      $('.js-membership-digital-icon').show();
      summarySubscriptionType.text('Digital Pass');
    } else {
      $('.js-membership-online-icon').show();
      summarySubscriptionType.text('Online Membership');
    }
  };

  const updateTermsDetails = () => {
    $('[data-temp]').remove();
    let count = 1;
    const seen = {}; // for double ups in layout B
    $('span:visible > [data-terms-details]').each((index, element) => {
      if (!seen.hasOwnProperty($(element).data('terms-details'))) {
        seen[$(element).data('terms-details')] = true;
        $(element).append('<sup data-temp>' + '*'.repeat(count) + '</sup>');
        $('.js-details-container').append(
          '<p data-temp><sup>' + '*'.repeat(count) + '</sup> ' + $(element).data('terms-details') + '</p>'
        );
        count += 1;
      }
    });
  };

  const enableProductSelection = () => {
    $('.js-payment-page-form').show();

    const selectedOpt = $('.js-membership-options-option-active');
    if (selectedOpt.length <= 0) { return; }
    $('.js-membership-product-options').show();

    $('.js-option-online').hide();
    $('.js-option-online-mag').hide();
    $('.js-digital-option').hide();

    const productType = selectedOpt.find('.js-membership-option-select').data('product-type');

    if (productType === 'online') {
      $('.js-purchase-label-magazine').addClass('js-dn');
      $('.js-purchase-label').removeClass('js-dn');
      $('.js-membership-payment-subscription').text('Select your Payment Frequency');
      $('.js-option-online').show();
    } else {
      $('.js-option-online-mag').show();
      $('.js-purchase-label').addClass('js-dn');
      $('.js-purchase-label-magazine').removeClass('js-dn');
      $('.js-membership-payment-subscription').text('Select your Payment Frequency');
    }

    updateTermsDetails(productType);
  };

  const skipProductSelection = () => {
    $('.js-payment-page-form').show();
    $('.js-membership-product-options').hide();
  };

  const resetProductSelection = () => {
    // Show the right set based on magazine option, IF there is a magazine selection
    const selectedOpt = $('.js-membership-options-option-active');
    if (selectedOpt.length <= 0) { return; }

    const productType = selectedOpt.find('.js-membership-option-select').data('product-type');
    if (!productType) { return; }

    $('.js-membership-product-options-label').hide();
    $(`.js-membership-product-options-label[data-product-type='${productType}']`).css({ display: 'block' });

    // Update text later in the form
    $('.js-membership-payment-subscription').text('Select your Payment Frequency');

    // Update selection if possible
    $('.js-membership-product-radio').prop('checked', false);
    const id = getProductSelection();
    if ($(`#membership_product_id-${id}_${productType}`).not(':disabled').length > 0) {
      $(`#membership_product_id-${id}_${productType}`).prop('checked', true);
    }
  };

  const enableMemberForm = () => {
    if (!hasSelection()) { return; }
    $('.js-payment-page-form').show();
    $('.js-membership-form').show();
    $('html').trigger('join.form.ready');
  };
  // Watched by the validation code

  const setContactVisibility = () => {
    if (!hasSelection()) { return; }
    const requiresContact = !!$('.js-membership-product-radio:checked + .js-membership-product-options-label')
      .data('requires-contact-number');

    if (requiresContact) {
      $('#member_member_attributes_contact_number').removeAttr('disabled');
      $('label[for=member_member_attributes_contact_number], #member_member_attributes_contact_number').show();
    } else {
      $('#member_member_attributes_contact_number').attr('disabled', 'disabled');
      $('label[for=member_member_attributes_contact_number], #member_member_attributes_contact_number').hide();
    }
  };

  const setAddressVisibility = () => {
    if (!hasSelection()) { return; }

    const requiresAddress = $('.js-membership-product-radio:checked')
      .data('product-type') === 'online_and_magazine';

    if (requiresAddress) {
      $('.js-address-form').show();
    } else {
      $('.js-address-form').hide();
    }
  };

  const paintOptionSelected = (opt) => {
    $('.js-membership-options-option')
      .removeClass('js-membership-options-option-active');
    $(opt).closest('.js-membership-options-option')
      .addClass('js-membership-options-option-active');
  };

  const addMobileClassesForOptionsAfterForm = (opt) => {
    const productType = $(opt).data('product-type');

    // Paint the other instance of this option, either at the top or the bottom...
    $(`.js-membership-options-option[data-product-type="${productType}"]`)
      .addClass('js-membership-options-option-active');

    // scroll to top of form when selecting option from below summary
    const offsetElm = $('.payment-page-content');
    const offset = offsetElm.offset().top;
    if (!skipScroll) {
      $(window).scrollTop(offset + offsetElm.height());
    }
    skipScroll = false;
  };

  const setBelowSummaryOptions = () => {
    $('.js-primary-option-set').appendTo('.js-options-below-summary');

    const selectedOpt = $('.js-membership-options-option-active');
    if (selectedOpt.length <= 0) { return; }
    const productType = selectedOpt.find('.js-membership-option-select').data('product-type');

    const belowSummaryOptions = $('.js-options-below-summary');
    const onlineOpt = belowSummaryOptions.find('div > [data-product-type="online"]');
    const magOpt = belowSummaryOptions.find('div > [data-product-type="online_and_magazine"]');
    const digitalOpt = belowSummaryOptions.find('div > [data-product-type="digital"]');

    onlineOpt.show();
    magOpt.show();
    digitalOpt.show();

    if (productType === 'online') {
      onlineOpt.hide();
    } else if (productType === 'online_and_magazine') {
      magOpt.hide();
    } else {
      digitalOpt.hide();
    }
  };

  const setDonationValue = () => {
    const donation = $('.js-donation-radio:checked');
    if (!donation.length) return;

    if (donation.val() === 'other') {
      donationValue = parseFloat(parseFloat(donationAmountInput.val() || 0.0).toFixed(2));
      if (donationValue) {
        donationAmountInput.val(donationValue);
      }
    } else if (donation.val() === 'none') {
      donationValue = 0.0;
      donationAmountInput.val('');
    } else {
      donationValue = parseFloat(donation.val());
      donationAmountInput.val('');
    }
  };

  const handleUpdateDonationValues = () => {
    setDonationValue();
    updateSummary();
  };

  const handleOtherDonationClick = () => {
    $('#donation_option_other').prop('checked', true);
    handleUpdateDonationValues();
  };

  const setupBasedOnHiddenParams = () => {
    if (!!membershipProductId.val()) {
      let selectedProduct;
      const productId = membershipProductId.val();
      const magazine = membershipReceivesMagazine.val();
      const productType = magazine === 'true' ? 'online_and_magazine' : 'online';

      // Digital pass...
      if ($('[data-digital-pass]').data('membership-product-id') === parseInt(productId)) {
        paintOptionSelected($('.js-membership-option-select[data-digital-pass]'));
        addMobileClassesForOptionsAfterForm(this);
        skipProductSelection();
      } else {
        // Regular product...
        $(`[data-product-type='${productType}']`)
          .closest('.js-membership-options-option')
          .addClass('js-membership-options-option-active');

        // Show the right set...
        $('.js-membership-product-options-label').hide();
        $(`.js-membership-product-options-label[data-product-type='${productType}']`).css({'display': 'block'});
        selectedProduct = $(`#membership_product_id-${productId}_${productType}`);
        selectedProduct.attr('checked', 'checked');
        enableProductSelection();
      }

      enableMemberForm();
      setContactVisibility();
      setAddressVisibility();
      setDonationValue();
      updateSummary();
      showDigitalTerms();
      updatePromoDetails();
      setBelowSummaryOptions();
      if ((promoCodeInput.val().length > 0) && (promoCodeId.val().length === 0)) {
        setInvalidPromoCode();
      }
    }
  };

  setupBasedOnHiddenParams();

  $('.js-membership-option-select').on('click', function () {
    paintOptionSelected(this);
    addMobileClassesForOptionsAfterForm(this);
    resetProductSelection();
    enableProductSelection();
    setContactVisibility();
    setAddressVisibility();
    updateHiddenMembershipFields();
    updateSummary();
    showDigitalTerms();
    updatePromoDetails();
    setBelowSummaryOptions();
  });

  $('[data-digital-pass]').on('click', function () {
    paintOptionSelected(this);
    addMobileClassesForOptionsAfterForm(this);
    resetProductSelection();
    skipProductSelection();
    enableMemberForm();
    setContactVisibility();
    setAddressVisibility();
    updateHiddenMembershipFields();
    updateSummary();
    showDigitalTerms();
    updatePromoDetails();
  });

  $('.js-membership-product-radio').on('click', () => {
    enableMemberForm();
    setContactVisibility();
    setAddressVisibility();
    updateHiddenMembershipFields();
    updateSummary();
    showDigitalTerms();
    updatePromoDetails();
  });

  donationAmountInput.on('change', handleUpdateDonationValues);
  donationRadios.on('change', handleUpdateDonationValues);
  donationAmountInput.on('click', handleOtherDonationClick);

  $('html').on('join.old.summary.update', updateSummary);

  if ($('.js-join-mag-only').length > 0) {
    skipScroll = true;
    $('[data-product-type="online_and_magazine"].js-membership-option-select').trigger('click');
    $('.js-options-below-summary').hide();
  }
});
