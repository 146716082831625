const canUseLocalStorage = () => {
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

const userHadConsentedToCookies = window.localStorage.getItem('cookie_consent') === 'true';
const cookieBanner = document.querySelector('.consent-confirmation-banner');

const showCookieBanner = () => {
  cookieBanner.classList.remove('dni');
};

const hideCookieBanner = () => {
  cookieBanner.classList.add('dni');
};

const updateCookiePreferences = (e) => {
  e.preventDefault();
  window.localStorage.setItem('cookie_consent', 'true');
  hideCookieBanner();
};

$(() => {
  if (canUseLocalStorage() === false) return;

  if (!userHadConsentedToCookies) {
    showCookieBanner();
    document.querySelector('.consent-confirmation-banner .dismiss').addEventListener('click', updateCookiePreferences);
    if (!$('#cta-popup-banner').hasClass('dn')) {
      $('#cta-popup-banner').addClass('dn');
    }
  }
});
