/* eslint-disable no-use-before-define */
$(() => {
  if ($('[data-favourites]').length <= 0) { return; }

  const setFavourited = (listTitle, url, id) => {
    $('[data-favourite-menu-control]').text('Saved');
    $('[data-favourites]').addClass('starred');

    $(`[data-favourites-list-title='${listTitle}'][data-favourite-url='${url}']`).parent().addClass('active-fav');
    $(`[data-favourites-list-title='${listTitle}'][data-favourite-url='${url}']`).hide();
    $(`[data-defavourite-list-title='${listTitle}'][data-defavourite-url='${url}']`).show();
    $(`[data-defavourite-list-title='${listTitle}'][data-defavourite-url='${url}']`).data('defavourite-path', `/member/bookmarks/${id}`);
  };

  const setUnfavourited = (listTitle, url) => {
    $(`[data-favourites-list-title='${listTitle}'][data-favourite-url='${url}']`).parent().removeClass('active-fav');
    $(`[data-favourites-list-title='${listTitle}'][data-favourite-url='${url}']`).show();
    $(`[data-defavourite-list-title='${listTitle}'][data-defavourite-url='${url}']`).hide();
    setFavourites();
  };

  const setUnfavouritedDropdown = () => {
    $('[data-favourite-menu-control]').text('Add to favourites');
    $('[data-favourites]').removeClass('starred');
  };

  const setFavourites = () => {
    $.getJSON($('[data-favourites]').data('favourites-index-path'), (data) => {
      let found = false;
      data.forEach((b) => {
        if (b.bookmark_list && (b.bookmarkable_id === $('[data-favourite]').data('bookmarkable-id')) && (b.url === $('[data-favourite]').data('favourite-url'))) {
          setFavourited(b.bookmark_list.title, b.url, b.id);
          found = true;
        }
      });
      if (!found) {
        setUnfavouritedDropdown();
      }
    });
  };

  const setFavouriteLists = () => {
    $.ajax({
      type: 'GET',
      url: $('[data-favourites]').data('favourites-list-index-path'),
      data: {
        bookmarkable_id: $('[data-favourites]').data('bookmarkable-id').toString(),
        bookmarkable_type: $('[data-favourites]').data('bookmarkable-type').toString()
      },
      success(response) {
        $('.favourites-select-options-cat').append(response);
        setFavourites();
        bindFavourite();
        bindDefavourite();
      }
    });
  };

  const bindDocumentClick = () => {
    $(document).click(() => {
      $('[data-favourite-menu]').hide();
      hideForm();
    });
  };

  const bindFavouriteMenu = () => {
    $('[data-favourite-menu]').click((e) => {
      e.stopPropagation();
      $('form#new_bookmark_list').hide();
      $('.favourites-select-options-new-create').show();
    });
  };

  const bindFavouriteMenuControl = () => {
    $('[data-favourite-menu-control]').click((e) => {
      e.stopPropagation();

      if ($('[data-favourite-menu]').is(':visible')) {
        $('form#new_bookmark_list').hide();
        $('.favourites-select-options-new-create').show();
        $('[data-favourite-menu]').hide();
      } else {
        $('[data-favourite-menu]').show();
      }
    });
  };

  const bindDefavourite = () => {
    $('[data-defavourite]').click(function (e) {
      e.stopPropagation();
      hideForm();

      const defavListTitle = $(this).data('defavourite-list-title');
      const defavUrl = $(this).data('defavourite-url');
      const defav = $.ajax({type: 'DELETE', url: $(this).data('defavourite-path')});

      defav.done(() => setUnfavourited(defavListTitle, defavUrl));
    });
  };

  const bindFavourite = () => {
    $('[data-favourite]').click(function (e) {
      e.stopPropagation();
      hideForm();

      const fav = $.post($(this).data('favourites-path'), {
        'bookmark[url]': $(this).data('favourite-url'),
        'bookmark[title]': $(this).data('favourite-title'),
        'bookmark[bookmark_list_id]': $(this).data('favourites-list-id'),
        // eslint-disable-next-line quote-props
        'bookmark_list_title': $(this).data('favourites-list-title')
      });

      fav.done((data) => setFavourited(data.bookmark_list.title, data.url, data.id));
    });
  };

  const bindShow = () => {
    $('.favourites-select-options-new-create').click(function (e) {
      e.stopPropagation();
      $(this).hide();
      $('form#new_bookmark_list').show();
      $('input#bookmark_list_title').focus();
    });
  };

  const bindSubmit = () => {
    $('form#new_bookmark_list').click((e) => e.stopPropagation());
    $('form#new_bookmark_list').on('submit', hideForm);
  };

  const hideForm = () => {
    $('form#new_bookmark_list').hide();
    $('.favourites-select-options-new-create').show();
  };

  setFavouriteLists();
  bindDocumentClick();
  bindFavouriteMenu();
  bindFavouriteMenuControl();
  bindShow();
  bindSubmit();

  $('html').on('consumer:favourites:refreshed', () => {
    setFavouriteLists();
    bindFavouriteMenu();
    bindShow();
    bindSubmit();
  });
});
