const $ctaBanner = $('#js-cta-popup-banner');
const $bannerLinkButton = $('#js-cta-popup-banner-link');
const $bannerClose = $('#js-cta-popup-banner-close');
const $formOpenBtn = $('#js-cta-popup-show-form');

const $ctaForm = $('#js-cta-popup-form');
const $formClose = $('#js-cta-popup-form-close');
const $formSubmit = $('.js-cta-popup-submit');

let linkClicked = false;

const hideCtaPopupBanner = () => {
  $ctaBanner.addClass('dn');
};

const showCtaPopupBanner = () => {
  $ctaBanner.removeClass('dn');
  window.dataLayer.push({
    event: 'cta_popup_view',
    title: $ctaBanner.data('cta-title')
  });
};

const clickCtaBannerClose = () => {
  hideCtaPopupBanner();

  if (linkClicked) return;
  // don't send the event if the banner link was clicked
  window.dataLayer.push({
    event: 'cta_popup_closed',
    type: 'banner',
    title: $ctaBanner.data('cta-title')
  });
};

const showCtaPopupForm = () => {
  hideCtaPopupBanner();
  $ctaForm.removeClass('dn');

  window.dataLayer.push({
    event: 'cta_popup_click',
    type: 'form_button',
    title: $ctaBanner.data('cta-title')
  });
};

const hideCtaPopupForm = () => {
  $ctaForm.addClass('dn');

  // don't send the event if the form was already submitted
  if ($formSubmit.length) {
    window.dataLayer.push({
      event: 'cta_popup_closed',
      type: 'form',
      title: $ctaBanner.data('cta-title')
    });
  }
};

const clickCtaPopupLink = () => {
  linkClicked = true;
  window.dataLayer.push({
    event: 'cta_popup_click',
    type: 'link_button',
    title: $ctaBanner.data('cta-title'),
    button_url: $bannerLinkButton.attr('href')
  });
};

const shouldDisplayBanner = () => {
  if (!$ctaBanner.length) return false;

  // check cookies banner isn't showing
  if (!$('.consent-confirmation-banner').hasClass('dni')) return false;
  if (window.localStorage.getItem('cookie_consent') !== 'true') return false;

  // Display the cta popup banner if the user has specified cta_popup as a param in the URL - This
  // makes it easy to test the banner and the form
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('cta_popup')) return true;

  const ctaId = $ctaBanner.data('cta-id');
  const cookieKey = `cta-popup-${ctaId}`;

  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(cookieKey) === 0) {
      return false;
    }
  }

  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  const cookieValue = `1; expires=${expirationDate.toUTCString()}'; path=/`;
  document.cookie = `${cookieKey}=${cookieValue}`;

  return true;
};

$(() => {
  $bannerClose.on('click', clickCtaBannerClose);
  $bannerLinkButton.on('click', clickCtaPopupLink);
  $formOpenBtn.on('click', showCtaPopupForm);
  $formClose.on('click', hideCtaPopupForm);

  if (shouldDisplayBanner()) {
    showCtaPopupBanner();
  }
});

$('body').on('click', (e) => {
  if (e.target.classList.contains('modal-overlay')) hideCtaPopupForm();
});
